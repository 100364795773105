// Project: GalaxyComplete
// Created: 9/25/20 by sammy

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, CardContent, Chip, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { useCurrentProject, useCurrentProjectID } from "../project/CurrentProjectState";
import { useAppServices } from "../app/services";
import { generatePath, Link, useMatch } from "react-router-dom";
import { CMC_MIGRATION_SUBROUTE, CMO_MIGRATION_SUBROUTE, getProjectSubRouteTemplate, INTEGRATIONS_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";
import {
    ApiReferenceIcon,
    CmcHostsIcon,
    CmoSystemsIcon,
    DashboardIcon,
    GlobalMapViewIcon,
    IntegrationsIcon,
    MigrationIcon,
    ProjectSettingsIcon,
    UserCountIcon,
} from "../project/ProjectCommon";
import { FeatureFlag } from "../app/AppGlobalService";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { ProjectDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { ReportIcon } from "../../common/CommonIcons";
import { useSideMenuListItemStyle } from "./LayoutCommon";
import { GoDotFill } from "react-icons/go";
import { API_DOCS_ROUTE } from "../help/HelpCommon";
import { useIsFeatureEnabled } from "../core/featureflag/FeatureFlags";

interface CurrentProjectInfoProps {}

export const CurrentProjectSideMenu: React.FC<CurrentProjectInfoProps> = observer((p) => {
    const project = useCurrentProject();
    const enabledProducts = project.getInfo().getEnabledProducts();
    const cmoSupported = useIsFeatureEnabled(FeatureFlag.CMO_SUPPORT);
    const isPrivateEdition = getIsPrivateEdition();

    if (!project) {
        return null;
    }

    return (
        <>
            <Box pb={1}>
                <OverviewSubMenu />
            </Box>
            {enabledProducts?.getCmc() && (
                <Box pb={1}>
                    <CmcMigrationSubMenu />
                </Box>
            )}
            {enabledProducts?.getCmo() && cmoSupported && (
                <Box pb={1}>
                    <CmoMigrationSubMenu />
                </Box>
            )}
            <Box pb={1}>
                <AutomationSubMenu />
            </Box>
            <Box pb={1}>
                <ManagementSubMenu />
            </Box>
        </>
    );
});

// ======================
// ProjectMenuHeader
// ======================

interface ProjectMenuHeaderProps {
    currentProject: ProjectDetails;
}

const ProjectMenuHeader: React.FC<ProjectMenuHeaderProps> = observer((p) => {
    const isPrivateEdition = getIsPrivateEdition();

    if (isPrivateEdition) {
        return (
            <Box pb={2} pt={1} pl={2}>
                <Card
                    sx={{
                        border: `1px solid rgba(255,255,255,.2)`,
                    }}
                >
                    <CardContent>
                        <Typography variant={"overline"} color={"textSecondary"}>
                            Current Project
                        </Typography>
                        <Typography variant={"subtitle2"}>{p.currentProject.getInfo().getName()}</Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    }

    return (
        <Box p={2} pt={1}>
            <Card
                sx={{
                    border: `1px solid rgba(255,255,255,.2)`,
                }}
            >
                <Box pr={2} pb={1} pl={2} pt={1}>
                    <Typography variant={"overline"} color={"textSecondary"}>
                        {`Current Project`}
                    </Typography>
                    <Typography variant={"subtitle2"}>{p.currentProject.getInfo().getName()}</Typography>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} pt={1}>
                        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                            <UserCountIcon size={14} />
                            <Typography>5</Typography>
                        </Stack>
                        <Chip size={"small"} label={"Admin"} />
                    </Stack>
                </Box>
            </Card>
        </Box>
    );
});

// ======================
// OverviewSubMenu
// ======================

interface OverviewSubMenuProps {}

export const OverviewSubMenu: React.FC<OverviewSubMenuProps> = (p) => {
    const projectId = useCurrentProjectID();
    const isPrivateEdition = getIsPrivateEdition();
    return (
        <>
            <Stack pl={SUBTITLE_LEFT_PADDING} direction={"row"} spacing={1} alignItems={"center"}>
                <DashboardIcon size={ICON_SIZE} />
                <Typography color={"textPrimary"} variant={"overline"}>
                    {"Overview"}
                </Typography>
            </Stack>
            <ProjectSubMenuListItem projectId={projectId} label={`Dashboard`} route={PROJECT_SUBROUTE.MANAGEMENT} />
            {!isPrivateEdition && (
                <ProjectSubMenuListItem
                    projectId={projectId}
                    icon={<GlobalMapViewIcon size={14} />}
                    label={`Locations`}
                    route={PROJECT_SUBROUTE.GLOBAL_MAP_VIEW}
                />
            )}
        </>
    );
};

// ======================
// DataMigrationSubMenu
// ======================

interface CmcMigrationSubMenuProps {}

export const CmcMigrationSubMenu: React.FC<CmcMigrationSubMenuProps> = observer((p) => {
    const currentProject = useCurrentProject();

    const projectId = currentProject.getInfo().getProjectId();
    return (
        <>
            <Stack pl={SUBTITLE_LEFT_PADDING} direction={"row"} spacing={1} alignItems={"center"}>
                <CmcHostsIcon size={ICON_SIZE} />
                <Typography color={"textPrimary"} variant={"overline"}>
                    {"Host-Based Migration"}
                </Typography>
            </Stack>
            <ProjectSubMenuListItem
                projectId={projectId}
                label={"Registered Hosts"}
                route={`${PROJECT_SUBROUTE.CMC_MIGRATIONS}/${CMC_MIGRATION_SUBROUTE.DEPLOYMENTS}`}
            />
            <ProjectSubMenuListItem
                projectId={projectId}
                label={"H2H Connections"}
                route={`${PROJECT_SUBROUTE.CMC_MIGRATIONS}/${CMC_MIGRATION_SUBROUTE.CONNECTIONS}`}
            />
            <ProjectSubMenuListItem
                projectId={projectId}
                label={"Migration Sessions"}
                route={`${PROJECT_SUBROUTE.CMC_MIGRATIONS}/${CMC_MIGRATION_SUBROUTE.SESSIONS}`}
            />
        </>
    );
});

// ======================
// CmoMigrationSubMenu
// ======================

interface CmoMigrationSubMenuProps {}

export const CmoMigrationSubMenu: React.FC<CmoMigrationSubMenuProps> = (p) => {
    const currentProject = useCurrentProject();
    const projectId = currentProject.getInfo().getProjectId();

    return (
        <>
            <Stack pl={SUBTITLE_LEFT_PADDING} direction={"row"} spacing={1} alignItems={"center"}>
                <CmoSystemsIcon size={14} />
                <Typography color={"textPrimary"} variant={"overline"}>
                    {"SAN Migration"}
                </Typography>
            </Stack>
            <ProjectSubMenuListItem
                projectId={projectId}
                label={"CMO Summary"}
                route={`${PROJECT_SUBROUTE.CMO_MIGRATIONS}/${CMO_MIGRATION_SUBROUTE.DASHBOARD}`}
            />
            <ProjectSubMenuListItem
                projectId={projectId}
                label={"Registered Appliances"}
                route={`${PROJECT_SUBROUTE.CMO_MIGRATIONS}/${CMO_MIGRATION_SUBROUTE.CMO_SYSTEMS}`}
            />
            <ProjectSubMenuListItem projectId={projectId} label={"FC Nexus"} route={`${PROJECT_SUBROUTE.CMO_MIGRATIONS}/${CMO_MIGRATION_SUBROUTE.NEXUS}`} />
            <ProjectSubMenuListItem
                projectId={projectId}
                label={"Migration Sessions"}
                route={`${PROJECT_SUBROUTE.CMO_MIGRATIONS}/${CMO_MIGRATION_SUBROUTE.MIGRATION_SESSIONS}`}
            />
            <ProjectSubMenuListItem
                projectId={projectId}
                label={"System Warnings"}
                route={`${PROJECT_SUBROUTE.CMO_MIGRATIONS}/${CMO_MIGRATION_SUBROUTE.WARNINGS}`}
            />
        </>
    );
};

// ======================
// AutomationSubMenu
// ======================

interface AutomationSubMenuProps {}

export const AutomationSubMenu: React.FC<AutomationSubMenuProps> = observer((p) => {
    const { appGlobalService } = useAppServices();
    const projectId = useCurrentProjectID();
    const isPrivateEdition = getIsPrivateEdition();

    return (
        <>
            <Stack pl={SUBTITLE_LEFT_PADDING} direction={"row"} spacing={1} alignItems={"center"}>
                <MigrationIcon size={ICON_SIZE} />
                <Typography color={"textPrimary"} variant={"overline"}>
                    {"Automation"}
                </Typography>
            </Stack>
            <ProjectSubMenuListItem
                projectId={projectId}
                label={
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Typography fontSize={14}>{"MigrateOps"}</Typography>
                        <Chip size={"small"} label={"NEW"} color={"primary"} />
                    </Stack>
                }
                route={PROJECT_SUBROUTE.MIGRATE_OPS}
            />
            <ProjectSubMenuListItem
                projectId={projectId}
                label={
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Typography fontSize={14}>{"MigrateOps Reference"}</Typography>
                    </Stack>
                }
                route={PROJECT_SUBROUTE.MIGRATE_OPS_DOCS}
            />
            {!isPrivateEdition && (
                <ProjectSubMenuListItem projectId={projectId} icon={<ApiReferenceIcon size={14} />} label={"API Reference"} externalLink={API_DOCS_ROUTE} />
            )}
        </>
    );
});

// ======================
// IntegrationsSubMenu
// ======================

interface IntegrationsSubMenuProps {}

export const IntegrationsSubMenu: React.FC<IntegrationsSubMenuProps> = observer((p) => {
    const currentProject = useCurrentProject();

    const projectId = currentProject.getInfo().getProjectId();
    return (
        <>
            <ProjectSubMenuListItem projectId={projectId} label={"Goodies"} route={PROJECT_SUBROUTE.INTEGRATIONS} default />
            <ProjectSubMenuListItem projectId={projectId} label={"Enabled"} route={PROJECT_SUBROUTE.INTEGRATIONS} subroute={INTEGRATIONS_SUBROUTE.ENABLED} />
        </>
    );
});

// ======================
// ManagementSubMenu
// ======================

interface ManagementSubMenuProps {}

export const ManagementSubMenu: React.FC<ManagementSubMenuProps> = (p) => {
    const projectId = useCurrentProjectID();
    return (
        <>
            <Stack pl={SUBTITLE_LEFT_PADDING} direction={"row"} spacing={1} alignItems={"center"}>
                <ProjectSettingsIcon size={ICON_SIZE} />
                <Typography color={"textPrimary"} variant={"overline"}>
                    {"Management"}
                </Typography>
            </Stack>
            <ProjectSubMenuListItem
                projectId={projectId}
                icon={<IntegrationsIcon size={14} />}
                label={"Integrations"}
                route={PROJECT_SUBROUTE.INTEGRATIONS}
                submenu={<IntegrationsSubMenu />}
            />
            <ProjectSubMenuListItem projectId={projectId} icon={<ReportIcon size={14} />} label={"Reports"} route={PROJECT_SUBROUTE.REPORTS} />
            <ProjectSubMenuListItem
                projectId={projectId}
                // icon={<ProjectSettingsIcon />}
                label={`Settings`}
                route={PROJECT_SUBROUTE.SETTINGS}
            />
        </>
    );
};

// ======================
// ProjectSubMenuListItem
// ======================
interface SideMenuListItemProps {
    projectId: string;
    label: string | React.ReactNode;
    icon?: React.ReactNode;
    route?: string;
    subroute?: string;
    submenu?: React.ReactNode;
    default?: boolean;
    forceOpen?: boolean;
    externalLink?: string;
}

const ProjectSubMenuListItem: React.FC<SideMenuListItemProps> = observer((p) => {
    const { appGlobalService } = useAppServices();
    const routeString = p.subroute ? `${p.route}/${p.subroute}` : p.route;
    const routeTemplate = getProjectSubRouteTemplate(routeString);
    const selected = p.default || p.subroute ? !!useMatch(routeTemplate) : !!useMatch(routeTemplate + "/*");
    const link = generatePath(routeTemplate, { projectId: p.projectId });
    const navFunction = p.externalLink ? () => window.open(p.externalLink, "_blank") : null;

    const onClick = () => {
        appGlobalService.setAppSubmenu(p.route);
    };

    const style = useSideMenuListItemStyle(!!p.subroute || !!p.default);

    return (
        <>
            <ListItemButton component={Link} to={p.externalLink ? null : link} onClick={navFunction || onClick} selected={selected} sx={style}>
                <Box pl={p.subroute || p.default ? 2 : 0} display={"flex"} alignItems={"center"}>
                    {(p.subroute || p.default) && (
                        <>
                            <GoDotFill />
                            &nbsp; &nbsp;
                        </>
                    )}
                    <ListItemText
                        primary={
                            <Typography fontSize={14} fontWeight={"inherit"}>
                                {p.label}
                            </Typography>
                        }
                    />
                </Box>
                {p.icon && (
                    <ListItemIcon
                        sx={{
                            minWidth: "30px",
                            color: "inherit",
                            pl: 1,
                        }}
                    >
                        {p.icon}
                    </ListItemIcon>
                )}
            </ListItemButton>
            {(appGlobalService.currentAppSubmenu === p.route || p.forceOpen) && p.submenu}
        </>
    );
});

const ICON_SIZE = 18;
const SUBTITLE_LEFT_PADDING = 2;
